import { graphql, PageProps } from 'gatsby';
import * as React from 'react';
import LSPCard from '../components/Card';
import Layout from '../components/Layout';
import { SEO } from '../components/Seo';
import {
  DuikerContent,
  HouthakkerContent,
  KlussenContent,
  LasserContent,
} from '../content/cards';

// Please note that you can use https://github.com/dotansimha/graphql-code-generator
// to generate all types from graphQL schema
interface IndexPageProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        title: string;
      };
    };
  };
}

export const pageQuery = graphql`
  query IndexQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

const IndexPage = ({ data }: IndexPageProps): JSX.Element => (
  <Layout>
    <SEO title={`Luc's special projects`} />
    <LSPCard id="duiken">
      <DuikerContent textColor="duiker" logoPlaced="left" />
    </LSPCard>
    <LSPCard id="tuinwerken">
      <HouthakkerContent textColor="tuinman" logoPlaced="right" />
    </LSPCard>
    <LSPCard id="lassen">
      <LasserContent textColor="lasser" logoPlaced="left" />
    </LSPCard>
    <LSPCard id="klussen">
      <KlussenContent textColor="klusser" logoPlaced="right" />
    </LSPCard>
  </Layout>
);
export default IndexPage;
