import React from 'react';

const colors: { [key: string]: string } = {
  klusser: 'text-[#fb9027]',
  duiker: 'text-[#004965]',
  tuinman: 'text-[#64b54e]',
  lasser: 'text-[#D32F2F]',
};

const ContentParagraph: React.FC<{ textColor: string }> = ({
  textColor,
  children,
}): JSX.Element => (
  <p
    className={`projectText text-base font-light leading-relaxed mt-0 mb-4 ${colors[textColor]}`}
  >
    {children}
  </p>
);

export default ContentParagraph;
