import React from 'react';
import {
  Heading6 as H6,
  Heading4 as H4,
  Paragraph,
} from '@material-tailwind/react';
import { StaticImage } from 'gatsby-plugin-image';
import ContentParagraph from '../../components/ContentParagraph';

interface Props {
  textColor: string;
  logoPlaced: string;
}

const LasserContent = ({ textColor, logoPlaced }: Props): JSX.Element => (
  <>
    <StaticImage
      src="../../images/Lasser.svg"
      alt="Duiker Cartoon"
      placeholder="blurred"
      layout="constrained"
      className={`${logoPlaced === 'left' ? '-scale-x-100' : 'sm:order-last'}`}
    />

    <article>
      <H6>
        <span className="font-project font-normal text-[#D32F2F]">Lassen</span>
      </H6>
      <H4 color="#292F39">
        <span className="font-project font-normal">Alles op maat</span>
      </H4>
      <hr className="w-1/5 border-t-4 border-[#D32F2F] relative left-1" />
      <div className="mb-4" />
      <ContentParagraph textColor={textColor}>
        Voor kleine en middelgrote constructies in ijzer, staal en inox kan ik u
        ook bijstaan.
      </ContentParagraph>
      <ContentParagraph textColor={textColor}>
        Geen bandwerk maar maatwerk volgens wens en mogelijkheid.
      </ContentParagraph>
      <ContentParagraph textColor={textColor}>
        U komt met een tekening of idee en ik zorg ervoor dat het correct wordt
        uitgevoerd.
      </ContentParagraph>
      <ContentParagraph textColor={textColor}>
        Ook laswerken op locatie kunnen zonder probleem worden uigevoerd. En dit
        met verschillende lasmethodes (electrode/MIG/MAG/TIG)
      </ContentParagraph>
    </article>

    <div
      className={`w-full h-full grid grid-flow-col sm:grid-flow-row gap-4 justify-around items-center max-h-[500px] ${
        logoPlaced === 'left' ? '' : 'sm:order-first'
      }`}
    >
      <StaticImage
        src="../../images/lasser01.png"
        alt="Laswerken gepersonaliseerd"
        placeholder="blurred"
        layout="constrained"
        objectFit="scale-down"
        className="max-h-full"
      />
      <StaticImage
        src="../../images/lasser02.png"
        alt="Laswerken camion"
        placeholder="blurred"
        layout="constrained"
        objectFit="scale-down"
        className="max-h-full"
      />
    </div>
  </>
);

export default LasserContent;
