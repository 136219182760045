import React from 'react';
import {
  Heading6 as H6,
  Heading4 as H4,
  Paragraph,
} from '@material-tailwind/react';
import { StaticImage } from 'gatsby-plugin-image';
import ContentParagraph from '../../components/ContentParagraph';

interface Props {
  textColor: string;
  logoPlaced: string;
}

const KlussenContent: React.FC<Props> = ({
  textColor,
  logoPlaced,
}): JSX.Element => (
  <>
    <StaticImage
      src="../../images/Werkman.svg"
      alt="Duiker Cartoon"
      placeholder="blurred"
      layout="constrained"
      className={`${logoPlaced === 'left' ? '-scale-x-100' : 'sm:order-last'}`}
    />

    <article>
      <H6>
        <span className="font-project font-normal text-[#fb9027]">Klussen</span>
      </H6>
      <H4 color="#292F39">
        <span className="font-project font-normal">Is zo gefixed</span>
      </H4>
      <hr className="w-1/5 border-t-4 border-[#fb9027] relative left-1" />
      <div className="mb-4" />
      <ContentParagraph textColor={textColor}>
        Bij mij kan u terecht voor heel diverse klussen in en rond uw huis en
        tuin.
      </ContentParagraph>
      <ContentParagraph textColor={textColor}>
        Van een lekkende waterkraan over een nieuwe gevel of garage tot het
        zetten van een tuinhuis. En alles wat er tussen zit.
      </ContentParagraph>
      <ContentParagraph textColor={textColor}>
        Ook renovaties en herstellingen allerhande zijn geen enkel probleem.
        Tevens help ik u ook met kleine terrassen en oprit(-renovaties)
      </ContentParagraph>
      <ContentParagraph textColor={textColor}>
        Alles wordt gedaan met de grootste zorg voor afwerking en kwaliteit. En
        steeds in nauw overleg met u zodat u achteraf niet voor vreemde
        verrassingen komt te staan of ontevreden moet achter blijven.
      </ContentParagraph>
    </article>

    <div
      className={`w-full h-full grid grid-flow-col sm:grid-flow-row gap-4 justify-around items-center max-h-[500px] ${
        logoPlaced === 'left' ? '' : 'sm:order-first'
      }`}
    >
      <StaticImage
        src="../../images/werkman01.png"
        alt="Oprit in Spanje"
        placeholder="blurred"
        layout="constrained"
        objectFit="scale-down"
        className="max-h-full"
      />
      <StaticImage
        src="../../images/werkman02.png"
        alt="Luc aan het werk. Metselt een muur"
        placeholder="blurred"
        layout="constrained"
        objectFit="scale-down"
        className="max-h-full"
      />
    </div>
  </>
);

export default KlussenContent;
