import React from 'react';
import { Card, CardBody } from '@material-tailwind/react';

interface Props {
  id: string;
}

// eslint-disable-next-line react/prop-types
const LSPCard: React.FC<Props> = ({ id, children }) => (
  <div id={id} className="mt-4">
    <Card>
      <CardBody className="grid gap-4 sm:grid-cols-3 content-center items-center justify-items-center">
        {children}
      </CardBody>
    </Card>
  </div>
);

export default LSPCard;
