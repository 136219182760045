import React from 'react';
import {
  Heading6 as H6,
  Heading4 as H4,
  Paragraph,
} from '@material-tailwind/react';
import { StaticImage } from 'gatsby-plugin-image';
import ContentParagraph from '../../components/ContentParagraph';

interface Props {
  textColor: string;
  logoPlaced: string;
}

const HouthakkerContent = ({ textColor, logoPlaced }: Props): JSX.Element => (
  <>
    <StaticImage
      src="../../images/Houthakker.svg"
      alt="Duiker Cartoon"
      placeholder="blurred"
      layout="constrained"
      className={`${logoPlaced === 'left' ? '-scale-x-100' : 'sm:order-last'} `}
    />

    <article>
      <H6>
        <span className="font-project font-normal text-[#64b54e]">
          Tuinwerken
        </span>
      </H6>
      <H4 color="#292F39">
        <span className="font-project font-normal">Zonder veel gezaag</span>
      </H4>
      <hr className="w-1/5 border-t-4 border-[#64b54e] relative left-1" />
      <div className="mb-4" />
      <ContentParagraph textColor={textColor}>
        Het boomzagen en snoeien zit bij ons al 3 generaties in de familie.
      </ContentParagraph>
      <ContentParagraph textColor={textColor}>
        Via mijn grootvader en vader zijn de kneepjes van het vak steeds door
        gegeven en probeer ik dan ook om de meest moeilijke bomen met een
        minimum aan schade om te leggen of te snoeien.
      </ContentParagraph>
      <ContentParagraph textColor={textColor}>
        De ervaring die in de loop van de jaren is opgebouwd, is dan ook heel
        handig bij het afwerken van deze zaagklussen.
      </ContentParagraph>
      <ContentParagraph textColor={textColor}>
        Ook bij stormschade probeer ik steeds zo snel als mogelijk te helpen.
      </ContentParagraph>
      <ContentParagraph textColor={textColor}>
        Indien gewenst kan alles opgeruimd en meegenomen worden. Of ter plaatste
        worden verhakseld.
      </ContentParagraph>
    </article>

    <div
      className={`w-full h-full grid grid-flow-col sm:grid-flow-row gap-4 justify-around items-center max-h-[500px] ${
        logoPlaced === 'left' ? '' : 'sm:order-first'
      }`}
    >
      <StaticImage
        src="../../images/tuinman02.png"
        alt="Tuinman Luc"
        placeholder="blurred"
        layout="constrained"
        objectFit="scale-down"
        className="max-h-full"
      />
      <StaticImage
        src="../../images/tuinman01.png"
        alt="Vellen van een grote boom"
        placeholder="blurred"
        layout="constrained"
        objectFit="scale-down"
        className="max-h-full"
      />
    </div>
  </>
);

export default HouthakkerContent;
