import React from 'react';
import { Heading6 as H6, Heading4 as H4 } from '@material-tailwind/react';
import { StaticImage } from 'gatsby-plugin-image';
import ContentParagraph from '../../components/ContentParagraph';

interface Props {
  textColor: string;
  logoPlaced: string;
}

const DuikerContent: React.FC<Props> = ({
  textColor,
  logoPlaced,
}): JSX.Element => (
  <>
    <StaticImage
      src="../../images/Duiker.svg"
      alt="Duiker Cartoon"
      placeholder="blurred"
      layout="constrained"
      className={`${logoPlaced === 'left' ? '-scale-x-100' : 'sm:order-last'}`}
    />

    <article>
      <H6>
        <span className="font-project font-normal text-[#004965]">Duiken</span>
      </H6>
      <H4>
        <span className="font-project font-normal">
          Ontdek een nieuwe wereld
        </span>
      </H4>
      <hr className="w-1/5 border-t-4 border-[#004965] relative left-1" />
      <div className="mb-4" />
      <ContentParagraph textColor={textColor}>
        Duiken is voor mij als hobby begonnen. De kennismaking met de
        onderwaterwereld begon al snorkelend, maar al snel was duidelijk dat ik
        hierin meer wilde.
      </ContentParagraph>
      <ContentParagraph textColor={textColor}>
        Het lesgeven is ontstaan vanuit mijn passie voor het duiken en de
        onderwaterwereld die ik graag wil delen met andere mensen. Het enige dat
        dan ook standaard is aan de opleiding is de prijs. Voor de rest wordt de
        opleiding volledig aangepast aan het leertempo van de student(en).
      </ContentParagraph>
      <ContentParagraph textColor={textColor}>
        Als PADI-duikinstructeur zit u goed bij mij voor heel diverse
        opleidingen en specialisaties. Zowel voor beginners als gevorderden, en
        dit van 8 tot 88 jaar.
      </ContentParagraph>
    </article>

    <div
      className={`w-full h-full grid grid-flow-col sm:grid-flow-row gap-4 justify-around items-center max-h-[500px] ${
        logoPlaced === 'left' ? '' : 'sm:order-first'
      }`}
    >
      <StaticImage
        src="../../images/duiker01.png"
        alt="Luc duiker instructeur"
        placeholder="blurred"
        layout="constrained"
        objectFit="scale-down"
        className="max-h-full"
      />
      <StaticImage
        src="../../images/duiker02.png"
        alt="Duiken in groep"
        placeholder="blurred"
        layout="constrained"
        objectFit="scale-down"
        className="max-h-full"
      />
    </div>
  </>
);

export default DuikerContent;
